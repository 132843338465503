var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('IAmOverlay', {
    attrs: {
      "loading": !!_vm.loading || !_vm.agencyWebConfigData
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var pristine = _ref.pristine;
        return [!_vm.haveWebConfig ? _c('BAlert', {
          staticClass: "p-1",
          attrs: {
            "show": "",
            "variant": "warning"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.webConfig.noWebConfig')) + " ")]) : _vm._e(), _c('b-card', {
          staticClass: "border mb-0 mt-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1",
            "no-body": ""
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between w-100"
              }, [_c('h5', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.webConfig.title')) + " ")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-1"
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t("agency.webConfig.field.websiteUrl"),
            "vid": "websiteUrl",
            "rules": "required|url"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var _vm$meData, _vm$meData$agency;
              var errors = _ref2.errors;
              return [_c('b-form-group', [_c('div', {
                staticClass: "d-flex gap-1 font-weight-bolder"
              }, [_c('label', {
                attrs: {
                  "for": "websiteUrl"
                }
              }, [_vm._v(_vm._s(_vm.$t("agency.webConfig.field.websiteUrl")))]), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])]), _c('b-form-input', {
                attrs: {
                  "id": "websiteUrl",
                  "state": errors.length > 0 ? false : null,
                  "disabled": _vm.isDisabledInput || ((_vm$meData = _vm.meData) === null || _vm$meData === void 0 ? void 0 : (_vm$meData$agency = _vm$meData.agency) === null || _vm$meData$agency === void 0 ? void 0 : _vm$meData$agency.id) == _vm.agencyId && !_vm.isRoleF1,
                  "name": _vm.$t("agency.webConfig.field.websiteUrl"),
                  "placeholder": _vm.$t("agency.webConfig.ph.websiteUrl")
                },
                on: {
                  "input": function input(val) {
                    return _vm.agencyWebConfigData.websiteUrl = val.replace(/.*\/\/(www\.)?/, '').replace(/\/.*/, '');
                  }
                },
                model: {
                  value: _vm.agencyWebConfigData.websiteUrl,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyWebConfigData, "websiteUrl", $$v);
                  },
                  expression: "agencyWebConfigData.websiteUrl"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(" (*) Vui lòng chỉ điền tên miền, không bao gồm [HTTPS] và kí tự đặc biệt [/&?...]. Ví dụ: vemaybay.one ")]), _c('BFormInvalidFeedback', {
                staticClass: "text-danger"
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t("agency.webConfig.field.useSeparateATS"),
            "vid": "useSeparateATS",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var _vm$meData2, _vm$meData2$agency;
              var errors = _ref3.errors;
              return [_c('b-form-group', [_c('div', {
                staticClass: "d-flex gap-1 font-weight-bolder"
              }, [_c('label', {
                attrs: {
                  "for": "useSeparateATS"
                }
              }, [_vm._v(_vm._s(_vm.$t("agency.webConfig.field.useSeparateATS")))])]), _c('b-form-checkbox', {
                staticClass: "py-50",
                attrs: {
                  "id": "useSeparateATS",
                  "switch": "",
                  "disabled": _vm.isDisabledInput || ((_vm$meData2 = _vm.meData) === null || _vm$meData2 === void 0 ? void 0 : (_vm$meData2$agency = _vm$meData2.agency) === null || _vm$meData2$agency === void 0 ? void 0 : _vm$meData2$agency.id) == _vm.agencyId && !_vm.isRoleF1
                },
                model: {
                  value: _vm.agencyWebConfigData.useSeparateATS,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyWebConfigData, "useSeparateATS", $$v);
                  },
                  expression: "agencyWebConfigData.useSeparateATS"
                }
              }), _c('BFormInvalidFeedback', {
                staticClass: "text-danger"
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t("agency.webConfig.field.logo"),
            "vid": "logo",
            "rules": "url"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var valid = _ref4.valid,
                errors = _ref4.errors;
              return [_c('b-form-group', [_c('div', {
                staticClass: "d-flex gap-1 font-weight-bolder"
              }, [_c('label', {
                attrs: {
                  "for": "logo"
                }
              }, [_vm._v(_vm._s(_vm.$t("agency.webConfig.field.logo")))])]), _c('b-input-group', {
                staticClass: "input-group custom-file-button",
                scopedSlots: _vm._u([!_vm.isDisabledInput ? {
                  key: "append",
                  fn: function fn() {
                    return [_c('label', {
                      staticClass: "btn btn-warning px-25 mb-0",
                      attrs: {
                        "for": "logo-file"
                      }
                    }, [_vm._v(" " + _vm._s(_vm.$t('agency.uploadImage')) + " ")])];
                  },
                  proxy: true
                } : null], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "logo",
                  "state": errors.length > 0 ? false : null,
                  "disabled": _vm.isDisabledInput,
                  "name": _vm.$t("agency.webConfig.field.logo"),
                  "placeholder": _vm.$t("agency.webConfig.ph.logo")
                },
                model: {
                  value: _vm.agencyWebConfigData.configs.logo,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyWebConfigData.configs, "logo", $$v);
                  },
                  expression: "agencyWebConfigData.configs.logo"
                }
              })], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(" (*) Ảnh logo có tỉ lệ 2:1. Ví dụ: 200x100, 300x150 (px) ")]), errors[0] ? _c('BFormInvalidFeedback', {
                staticClass: "text-danger",
                staticStyle: {
                  "display": "block !important"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1), _c('b-form-file', {
                ref: "logoRef",
                staticClass: "d-none",
                attrs: {
                  "id": "logo-file",
                  "accept": ".jpg, .png",
                  "disabled": _vm.isDisabledInput,
                  "plain": ""
                },
                on: {
                  "input": _vm.inputImageLogoFormData
                },
                model: {
                  value: _vm.logoFile,
                  callback: function callback($$v) {
                    _vm.logoFile = $$v;
                  },
                  expression: "logoFile"
                }
              }), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [valid && _vm.agencyWebConfigData.configs.logo ? _c('b-img-lazy', {
                staticClass: "p-50 border mt-2",
                staticStyle: {
                  "max-height": "30%",
                  "max-width": "40%"
                },
                attrs: {
                  "src": _vm.agencyWebConfigData.configs.logo,
                  "center": "",
                  "alt": _vm.$t('imgNotFound')
                }
              }) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t("agency.webConfig.field.banner"),
            "vid": "banner",
            "rules": "url"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var valid = _ref5.valid,
                errors = _ref5.errors;
              return [_c('b-form-group', [_c('div', {
                staticClass: "d-flex gap-1 font-weight-bolder"
              }, [_c('label', {
                attrs: {
                  "for": "banner"
                }
              }, [_vm._v(_vm._s(_vm.$t("agency.webConfig.field.banner")))])]), _c('b-input-group', {
                staticClass: "input-group custom-file-button",
                scopedSlots: _vm._u([!_vm.isDisabledInput ? {
                  key: "append",
                  fn: function fn() {
                    return [_c('label', {
                      staticClass: "btn btn-warning px-25 mb-0",
                      attrs: {
                        "for": "banner-file"
                      }
                    }, [_vm._v(" " + _vm._s(_vm.$t('agency.uploadImage')) + " ")])];
                  },
                  proxy: true
                } : null], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "banner",
                  "state": errors.length > 0 ? false : null,
                  "disabled": _vm.isDisabledInput,
                  "name": _vm.$t("agency.webConfig.field.banner"),
                  "placeholder": _vm.$t("agency.webConfig.ph.banner")
                },
                model: {
                  value: _vm.agencyWebConfigData.configs.banner,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyWebConfigData.configs, "banner", $$v);
                  },
                  expression: "agencyWebConfigData.configs.banner"
                }
              })], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(" (*) Ảnh logo có tỉ lệ 16:9, ví dụ: 1920x1080 (px). Kích thước ảnh nhỏ hơn 1mb ")]), errors[0] ? _c('BFormInvalidFeedback', {
                staticClass: "text-danger",
                staticStyle: {
                  "display": "block !important"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1), _c('b-form-file', {
                ref: "bannerRef",
                staticClass: "d-none",
                attrs: {
                  "id": "banner-file",
                  "accept": ".jpg, .png",
                  "disabled": _vm.isDisabledInput,
                  "plain": ""
                },
                on: {
                  "input": function input($event) {
                    return _vm.inputImageBannerFormData(1);
                  }
                },
                model: {
                  value: _vm.bannerFile,
                  callback: function callback($$v) {
                    _vm.bannerFile = $$v;
                  },
                  expression: "bannerFile"
                }
              }), valid && _vm.agencyWebConfigData.configs.banner ? _c('b-img-lazy', {
                staticClass: "p-50",
                staticStyle: {
                  "max-height": "100%",
                  "max-width": "100%"
                },
                attrs: {
                  "src": _vm.agencyWebConfigData.configs.banner,
                  "center": "",
                  "alt": _vm.$t('imgNotFound')
                }
              }) : _vm._e()];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t("agency.webConfig.field.title"),
            "vid": "title",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('b-form-group', [_c('div', {
                staticClass: "d-flex gap-1 font-weight-bolder"
              }, [_c('label', {
                attrs: {
                  "for": "title"
                }
              }, [_vm._v(_vm._s(_vm.$t("agency.webConfig.field.title")))]), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])]), _c('b-input-group', {
                staticClass: "input-group custom-file-button"
              }, [_c('b-form-input', {
                attrs: {
                  "id": "title",
                  "state": errors.length > 0 ? false : null,
                  "disabled": _vm.isDisabledInput,
                  "name": _vm.$t("agency.webConfig.field.title"),
                  "placeholder": _vm.$t("agency.webConfig.ph.title")
                },
                model: {
                  value: _vm.agencyWebConfigData.title,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyWebConfigData, "title", $$v);
                  },
                  expression: "agencyWebConfigData.title"
                }
              })], 1), errors[0] ? _c('BFormInvalidFeedback', {
                staticClass: "text-danger",
                staticStyle: {
                  "display": "block !important"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1), _vm.isRoleF1 ? _c('b-card', {
          staticClass: "border mb-0 mt-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1",
            "no-body": ""
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between w-100"
              }, [_c('h5', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.webConfig.smtpOptions')) + " ")]), _vm.useSMTP && _vm.haveWebConfig && _vm.isDisabledInput ? _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(255, 255, 255, 0.15)',
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                attrs: {
                  "variant": "outline-success"
                },
                on: {
                  "click": function click() {
                    _vm.emailTest = _vm.cloneDeep(_vm.blankEmailTest);
                    _vm.$bvModal.show('test-send-email-modal');
                  }
                }
              }, [_vm._v(" Test Send Email ")]) : _vm._e(), _c('BFormCheckbox', {
                attrs: {
                  "disabled": _vm.isDisabledInput,
                  "switch": ""
                },
                on: {
                  "change": function change(value) {
                    if (!value) {
                      _vm.agencyWebConfigData.smtpOptions = {
                        host: '',
                        port: '',
                        username: '',
                        password: '',
                        applyForEmployee: false
                      };
                    } else {
                      _vm.agencyWebConfigData.smtpOptions = {
                        host: 'smtp.gmail.com',
                        port: '587',
                        username: '',
                        password: '',
                        applyForEmployee: true
                      };
                    }
                  }
                },
                model: {
                  value: _vm.useSMTP,
                  callback: function callback($$v) {
                    _vm.useSMTP = $$v;
                  },
                  expression: "useSMTP"
                }
              })], 1)];
            },
            proxy: true
          }], null, true)
        }, [_vm.useSMTP ? _c('b-card-body', {
          staticClass: "p-1"
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t("agency.webConfig.field.emailType"),
            "vid": "emailType",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('b-form-group', {
                class: {
                  'is-invalid': errors[0]
                }
              }, [_c('div', {
                staticClass: "d-flex gap-1 font-weight-bolder"
              }, [_c('label', {
                attrs: {
                  "for": "emailType"
                }
              }, [_vm._v(_vm._s(_vm.$t("agency.webConfig.field.emailType")))]), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])]), _c('v-select', {
                attrs: {
                  "id": "emailType",
                  "name": _vm.$t('agency.webConfig.field.emailType'),
                  "options": _vm.emailTypeOptions,
                  "clearable": false,
                  "disabled": _vm.isDisabledInput,
                  "label": "label",
                  "placeholder": _vm.$t('agency.webConfig.ph.emailType'),
                  "reduce": function reduce(val) {
                    return val.value;
                  }
                },
                on: {
                  "input": function input(val) {
                    return _vm.onEmailTypeChange(val);
                  }
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(_ref8) {
                    var label = _ref8.label,
                      icon = _ref8.icon;
                    return [_c('div', {
                      staticClass: "d-flex gap-1"
                    }, [_c('IAmIcon', {
                      attrs: {
                        "icon": icon,
                        "size": "16"
                      }
                    }), _vm._v(" " + _vm._s(_vm.$t("agency.webConfig.emailType.".concat(label))) + " ")], 1)];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(_ref9) {
                    var label = _ref9.label,
                      icon = _ref9.icon;
                    return [_c('div', {
                      staticClass: "d-flex gap-1"
                    }, [_c('IAmIcon', {
                      attrs: {
                        "icon": icon,
                        "size": "16"
                      }
                    }), _vm._v(" " + _vm._s(_vm.$t("agency.webConfig.emailType.".concat(label))) + " ")], 1)];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.emailType,
                  callback: function callback($$v) {
                    _vm.emailType = $$v;
                  },
                  expression: "emailType"
                }
              }), _c('BFormInvalidFeedback', {
                staticClass: "text-danger"
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t("agency.webConfig.field.host"),
            "vid": "host",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('b-form-group', [_c('div', {
                staticClass: "d-flex gap-1 font-weight-bolder"
              }, [_c('label', {
                attrs: {
                  "for": "host"
                }
              }, [_vm._v(_vm._s(_vm.$t("agency.webConfig.field.host")))]), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])]), _c('b-form-input', {
                attrs: {
                  "id": "host",
                  "disabled": _vm.isDisabledInput,
                  "state": errors.length > 0 ? false : null,
                  "name": _vm.$t("agency.webConfig.field.host"),
                  "placeholder": _vm.$t("agency.webConfig.ph.host")
                },
                model: {
                  value: _vm.agencyWebConfigData.smtpOptions.host,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyWebConfigData.smtpOptions, "host", $$v);
                  },
                  expression: "agencyWebConfigData.smtpOptions.host"
                }
              }), _c('BFormInvalidFeedback', {
                staticClass: "text-danger"
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t("agency.webConfig.field.email"),
            "vid": "username",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref11) {
              var errors = _ref11.errors;
              return [_c('b-form-group', [_c('div', {
                staticClass: "d-flex gap-1 font-weight-bolder"
              }, [_c('label', {
                attrs: {
                  "for": "username"
                }
              }, [_vm._v(_vm._s(_vm.$t("agency.webConfig.field.email")))]), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])]), _c('b-form-input', {
                attrs: {
                  "id": "username",
                  "autocomplete": "off",
                  "type": "email",
                  "disabled": _vm.isDisabledInput,
                  "state": errors.length > 0 ? false : null,
                  "name": _vm.$t("agency.webConfig.field.email"),
                  "placeholder": _vm.$t("agency.webConfig.ph.email")
                },
                model: {
                  value: _vm.agencyWebConfigData.smtpOptions.username,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyWebConfigData.smtpOptions, "username", $$v);
                  },
                  expression: "agencyWebConfigData.smtpOptions.username"
                }
              }), _c('BFormInvalidFeedback', {
                staticClass: "text-danger"
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t("agency.webConfig.field.password"),
            "vid": "password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var errors = _ref12.errors;
              return [_c('b-form-group', [_c('div', {
                staticClass: "d-flex gap-1 font-weight-bolder"
              }, [_c('label', {
                attrs: {
                  "for": "password"
                }
              }, [_vm._v(_vm._s(_vm.$t("agency.webConfig.field.password")))]), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])]), _c('b-form-input', {
                attrs: {
                  "id": "password",
                  "autocomplete": "new-password",
                  "type": "password",
                  "state": errors.length > 0 ? false : null,
                  "disabled": _vm.isDisabledInput,
                  "name": _vm.$t("agency.webConfig.field.password"),
                  "placeholder": _vm.$t("agency.webConfig.ph.password")
                },
                on: {
                  "input": function input($event) {
                    _vm.agencyWebConfigData.smtpOptions.password = $event.replace(/\s+/g, '');
                    ;
                  }
                },
                model: {
                  value: _vm.agencyWebConfigData.smtpOptions.password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyWebConfigData.smtpOptions, "password", $$v);
                  },
                  expression: "agencyWebConfigData.smtpOptions.password"
                }
              }), _c('BFormInvalidFeedback', {
                staticClass: "text-danger"
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t("agency.webConfig.field.port"),
            "vid": "port",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref13) {
              var errors = _ref13.errors;
              return [_c('b-form-group', [_c('div', {
                staticClass: "d-flex gap-1 font-weight-bolder"
              }, [_c('label', {
                attrs: {
                  "for": "port"
                }
              }, [_vm._v(_vm._s(_vm.$t("agency.webConfig.field.port")))]), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])]), _c('b-form-input', {
                attrs: {
                  "id": "port",
                  "disabled": _vm.isDisabledInput,
                  "state": errors.length > 0 ? false : null,
                  "name": _vm.$t("agency.webConfig.field.port"),
                  "placeholder": _vm.$t("agency.webConfig.ph.port")
                },
                model: {
                  value: _vm.agencyWebConfigData.smtpOptions.port,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyWebConfigData.smtpOptions, "port", $$v);
                  },
                  expression: "agencyWebConfigData.smtpOptions.port"
                }
              }), _c('BFormInvalidFeedback', {
                staticClass: "text-danger"
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', [_c('div', {
          staticClass: "d-flex gap-1 font-weight-bolder align-items-center"
        }, [_c('label', {
          attrs: {
            "for": "applyForEmployee"
          }
        }, [_vm._v(_vm._s(_vm.$t("agency.webConfig.field.applyForEmployee")))]), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])]), _c('BFormCheckbox', {
          attrs: {
            "disabled": _vm.isDisabledInput,
            "switch": ""
          },
          model: {
            value: _vm.agencyWebConfigData.smtpOptions.applyForEmployee,
            callback: function callback($$v) {
              _vm.$set(_vm.agencyWebConfigData.smtpOptions, "applyForEmployee", $$v);
            },
            expression: "agencyWebConfigData.smtpOptions.applyForEmployee"
          }
        })], 1)], 1)], 1)], 1) : _vm._e()], 1) : _vm._e(), _c('b-row', {
          staticClass: "mt-50"
        }, [_c('b-col', {
          staticClass: "d-flex-center gap-1",
          attrs: {
            "cols": "12"
          }
        }, [!_vm.haveWebConfig ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.createHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('create')) + " ")]) : _vm._e(), _vm.haveWebConfig && _vm.isEdit ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": _vm.updateHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('saveChanges')) + " ")]) : _vm._e(), _vm.isDisabledInput ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              _vm.isEdit = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('edit')) + " ")]) : _vm._e(), _vm.haveWebConfig && _vm.isEdit ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.confirmCancelEdit(pristine);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('cancelEdit')) + " ")]) : _vm._e()], 1)], 1)];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "test-send-email-modal",
      "centered": "",
      "no-close-on-esc": "",
      "no-close-on-backdrop": "",
      "title": "Test send email"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('b-button', {
          staticClass: "float-right",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.testSendEmail
          }
        }, [_vm._v(" Send ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t("agency.webConfig.field.email"),
      "vid": "emailTest",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-group', [_c('div', {
          staticClass: "d-flex gap-1 font-weight-bolder"
        }, [_c('label', {
          attrs: {
            "for": "emailTest"
          }
        }, [_vm._v(_vm._s(_vm.$t("agency.webConfig.field.email")))])]), _c('b-input-group', {
          staticClass: "input-group custom-file-button"
        }, [_c('b-form-input', {
          attrs: {
            "id": "emailTest",
            "state": errors.length > 0 ? false : null,
            "name": _vm.$t("agency.webConfig.field.email"),
            "placeholder": _vm.$t("agency.webConfig.ph.email")
          },
          model: {
            value: _vm.emailTest.email,
            callback: function callback($$v) {
              _vm.$set(_vm.emailTest, "email", $$v);
            },
            expression: "emailTest.email"
          }
        })], 1), errors[0] ? _c('BFormInvalidFeedback', {
          staticClass: "text-danger",
          staticStyle: {
            "display": "block !important"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t("agency.webConfig.field.content"),
      "vid": "content",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('b-form-group', [_c('div', {
          staticClass: "d-flex gap-1 font-weight-bolder"
        }, [_c('label', {
          attrs: {
            "for": "content"
          }
        }, [_vm._v(_vm._s(_vm.$t("agency.webConfig.field.content")))])]), _c('b-input-group', {
          staticClass: "input-group custom-file-button"
        }, [_c('b-form-input', {
          attrs: {
            "id": "content",
            "state": errors.length > 0 ? false : null,
            "name": _vm.$t("agency.webConfig.field.content"),
            "placeholder": _vm.$t("agency.webConfig.ph.content")
          },
          model: {
            value: _vm.emailTest.content,
            callback: function callback($$v) {
              _vm.$set(_vm.emailTest, "content", $$v);
            },
            expression: "emailTest.content"
          }
        })], 1), errors[0] ? _c('BFormInvalidFeedback', {
          staticClass: "text-danger",
          staticStyle: {
            "display": "block !important"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }